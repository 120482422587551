import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/vercel/path0/src/components/LayoutPost.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <div {...{
      "className": "hero"
    }}>{`
  `}<span parentName="div" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "800px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/7a496ab485fe18ffd65b177451f2e37f/4c33a/spelunk.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "100%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/svg+xml,%3csvg%20xmlns=\\'http://www.w3.org/2000/svg\\'%20width=\\'400\\'%20height=\\'400\\'%20viewBox=\\'0%200%20400%20400\\'%20preserveAspectRatio=\\'none\\'%3e%3cpath%20d=\\'M0%20200v200h401V0H0v200M334%2052c-8%202-14%205-15%207l-27%2058c-6%2013-11%2026-12%2032l-2%206-1%204c0%203-1%203-6%200l-2-3%201-5c0-2%200-3-3-4-4%200-6%202-6%207%201%202%201%203-1%204-1%201-2%202-2%207-1%207%200%209%204%2013l2%202v34c0%2032%200%2034-2%2045l-3%2028c-1%2018-2%2029-4%2033v5c2-2%204-16%205-34%200-10%202-23%203-29%204-21%204-24%203-52v-29l1-3%206%204%206%205c-1%202%200%205%201%207l1%207c-1%202%205%205%207%204%201-1%201-1-1-3-3-4-4-7-4-13l-2-9c-1-2-1-2%202-4%202-2%204-2%205-1%2012%209%2014%209%2015%204%202-7-1-9-3-3-1%203-4%202-6-2-4-6-5-6-11-5h-6l2-3%201-4%202-3v-2c-4-3%2010-39%2028-74l8-18c3-7%203-7%2010-10%205-2%207-2%2012-2h6l-3-1h-9\\'%20fill=\\'%23d3d3d3\\'%20fill-rule=\\'evenodd\\'/%3e%3c/svg%3e')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/7a496ab485fe18ffd65b177451f2e37f/c4d1c/spelunk.webp 248w", "/static/7a496ab485fe18ffd65b177451f2e37f/488b2/spelunk.webp 496w", "/static/7a496ab485fe18ffd65b177451f2e37f/0c38f/spelunk.webp 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/7a496ab485fe18ffd65b177451f2e37f/ea035/spelunk.jpg 248w", "/static/7a496ab485fe18ffd65b177451f2e37f/bc6a1/spelunk.jpg 496w", "/static/7a496ab485fe18ffd65b177451f2e37f/4c33a/spelunk.jpg 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/7a496ab485fe18ffd65b177451f2e37f/4c33a/spelunk.jpg",
              "alt": "test",
              "title": "test",
              "loading": "lazy",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span>
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      